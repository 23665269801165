.nav-current {
  font-weight: bold;
}

nav {
  display: flex;
  flex-direction: row;

  a {
    font-size: 1.5em;
    margin: 0.2em 1.8em;
  }
}

.sidebar {
}
