$sidebarPercent: 30%;

.topbar {
  margin-top: 2em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #131313;
  padding: 0em 1em;
}

footer {
  width: 100%;
  background-color: #131313;
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  div {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  img {
    width: 1.5em;
  }
}

aside {
  width: $sidebarPercent;
  float: right;
  height: 50%;
  padding-left: 1em;
  border-left: solid $bodyColor;
  background-color: #131313;
}

.content {
  width: 100% - $sidebarPercent - 10%;
  text-align: justify;
  padding-left: 1.5em;
  figure {
    display: block;
    margin: 1em;
    img {
      display: block;
      vertical-align: top;
      margin: auto;
    }
    figcaption {
      padding-top: .5em;
      text-align: center;
      color: $inactiveColor;
    }
  }
}

a {
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: $accentColor;
}

aside > ul {
  list-style-type: "- ";
}
