$backgroundColor: #1a1a1a;
$bodyColor: #E5E5E5;
$accentColor: #85BAD1;
$inactiveColor: #686868;

body {
  background: $backgroundColor;
  color: $bodyColor;
  font-family: Tahoma, sans-serif;
  //font-family: Merriweather, Georgia, serif;
  margin: auto;
  max-width: 60%;
}
